.container {
  display: flex;
}

.additionalSettingsGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.groupTitle {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--color-typo-secondary);
}
