.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.bottomButtons {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  padding: 8px 12px;
  background-color: var(--color-bg-default);
}
