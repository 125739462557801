.container {
  display: flex;
}

.additionalSettingsGroup {
  display: flex;
  flex-direction: column;
}

.additionalSettingsList {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.groupTitle {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--color-typo-secondary);
}
