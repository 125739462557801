.container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 24px;
  margin-bottom: 24px;
}

.row {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.buttonsGroup {
  display: flex;
  column-gap: 24px;
}

.badgeSection {
  display: flex;
  align-items: center;
  column-gap: 24px;
}
