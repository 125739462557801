.container {
  width: 528px;
  padding: 32px 20px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
  margin-bottom: 12px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 44px;
}

.buttonGroup {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.section {
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 24px;
}

.sectionItem {
  display: flex;
  flex-direction: column;
}

.sectionItemTitle {
  font-size: 16px;
  color: var(--color-typo-secondary);
  margin-bottom: 8px;
}

.sectionItemValue {
  font-size: 16px;
  white-space: pre-line;
}

.rowResult {
  height: 50px;
  margin-bottom: 24px
}

.buttonCancelGroup {
  display: flex;
  column-gap: 24px;
  align-items: center;
}
