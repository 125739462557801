.container {
  display: flex;
  flex-direction: column;
  margin: 16px 8px 9px 8px;
  column-gap: 12px;
}

.checkBoxRow {
  display: flex;
  margin-bottom: 4px;
}

.title {
  margin-left: 8px;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-typo-secondary);
}

.rightPart {
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: flex-end;
}

.textButton {
  font-size: 12px;
  line-height: 15px;
  color: var(--color-control-typo-secondary);
  cursor: pointer;
}
