.container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px 20px 20px 20px;
  overflow: hidden;
}

.tableContainer {
  display: flex;
  width: 100%;
  height: calc(100vh - 414px);
  flex-direction: column;
  overflow: hidden;
}

.tableWrapper {
  border-radius: 4px;
  border: 1px solid var(--color-control-bg-border-default);
  background-color: var(--color-bg-default);
  overflow: auto;
}

.loaderContainer {
  display: flex;
  margin-top: -2px;
  height: 22px;
  justify-content: center;
  align-items: center;
  width: 30px;
}
