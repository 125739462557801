.filterContainer {
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #00416633;
  border-radius: 4px;
  width: 400px;
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filterWrapper {
  width: 100%;
}

.buttonsWrapper {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.container, .loader {
  display: flex;
  flex-direction: column;
}

.textField {
  display: flex;
  width: 100%;
  flex-grow: 0;
  margin-bottom: 8px;
}

.resetBtnWrapper {
  font-size: 14px;
  font-weight: 400;
  margin: 16px 0;
  display: flex;
  height: 20px;
  align-items: center;
}

.resetBtn {
  cursor: pointer;
}

.loader {
  justify-content: center;
  min-height: 40px;
}
