.container {
  display: flex;
}

.value {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.placeholder {
  display: flex;
  align-items: center;
  height: 40px;
  color: var(--color-typo-ghost);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.button {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.textField {
  width: 600px;
  align-items: center;
  margin-right: 4px;
}

.btnSave {
  margin-left: 20px;
}

.loaderContainer {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.loaderText {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-link);
  margin-left: 20px;
  margin-right: 14px;
}

.loader {
  width: 30px;
}
