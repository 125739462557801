.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 165px;
  min-width: 680px;
  max-width: 767px;
  padding: 20px 24px 16px 24px;
  border-radius: 4px;
  border: 1px solid #B8CAD5;
  background-color: var(--color-bg-default);
  cursor: pointer;
}

.topRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4px;
}

.description {
  width: 400px;
  min-height: 19px;
  font-size: 14px;
  line-height: 140%;

  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.descriptionSystem {
  width: 400px;
  min-height: 59px;
  font-size: 14px;
  line-height: 140%;
  color: var(--color-typo-secondary);

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;


}

.bottomRow {
  display: flex;
  margin-top: 12px;
  width: 100%;
  justify-content: space-between;
}

.bottomRowSystem {
  margin-top: 12px;
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.hierarchy {
  width: 60%;
  height: 68px;
  display: flex;
  margin-right: 32px;
  flex-direction: column;
  row-gap: 4px;
}

.hierarchyRow {
  display: flex;
  align-items: center;
}

.ico {
  margin-right: 4px;
}

.icoFirst {
  margin-left: 10px;
  margin-right: 4px;
}

.icoSecond {
  margin-left: 20px;
  margin-right: 4px;
}

.hierarchyText {
  max-width: 340px;
  font-size: 14px;
  line-height: 140%;
  color: var(--color-typo-secondary);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.destination {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
}

.destinationIco {
  margin-bottom: 4px;
  height: 20px;
  object-fit: contain;
}

.destinationTitle {
  font-size: 14px;
  line-height: 140%;
  max-width: 240px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
