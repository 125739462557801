.container {
  display: flex;
  width: 84px;
  padding: 0 4px;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-secondary);
  cursor: default !important;
}
