.container {
  display: flex;
  flex-direction: column;
  width: 500px;
  background-color: var(--color-bg-default);
}

.row {
  display: flex;
  padding: 0 12px;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
  height: 60px;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  height: calc(100vh - 170px);
}

.contentWithoutFooter {
  height: calc(100vh - 122px);
}
