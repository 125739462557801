.container {
  display: flex;
}

.secondaryColor {
  color: var(--color-typo-secondary);
}

.row {
  display: flex;
  column-gap: 8px;
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: inherit;
}
