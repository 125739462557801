.hierarchy {
  display: flex;
  flex-direction: column;
}

.hierarchyRow {
  display: flex;
  align-items: center;
}

.ico {
  margin-right: 4px;
}

.icoFirst {
  margin-left: 10px;
  margin-right: 4px;
}

.icoSecond {
  margin-left: 20px;
  margin-right: 4px;
}

.hierarchyText {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
