.container {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 632px;
  max-width: 632px;
  height: 100%;
  overflow-y: auto;
}

.scrollContainer {
  overflow-y: auto;
  height: 100%;
  background-color: var(--color-bg-default);
  padding-top: 24px;
}

.section {
  display: flex;
  padding: 0 24px;
  flex-direction: column;
  row-gap: 8px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}

.shortBottom {
  margin-bottom: 8px;
}

.sectionTitle {
  color: var(--color-typo-secondary);
}

.combineText {
  display: flex;
}

.requiredSign {
  font-size: 16px;
  margin-left: 4px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-typo-alert);
}

.grayBottom {
  display: flex;
  height: 64px;
  background-color: var(--color-bg-secondary);
}

.bottomButtons {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  padding: 8px 12px;
  background-color: var(--color-bg-default);
}

.buttonsGroup {
  display: flex;
  column-gap: 20px;
}

.textFieldContainer {
  display: flex;
  flex-direction: column;
}

.sectionAlert {
  height: 18px;
  margin-top: 4px;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-typo-alert);
}

.sectionValue {
  margin-top: -10px;
  color: var(--color-typo-primary);
}

.bottomMargin {
  margin-bottom: 8px;
}

.topMargin {
  margin-top: 4px;
}


.tooltip {
  margin-top: 2px !important;
}

.informer {
  margin-left: 4px !important;
  cursor: pointer !important;
}

.ico {
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  margin-left: 4px;
}
