.container {
  display: flex;
  width: 640px;
  height: 100%;
  flex-direction: column;
}

.topRow {
  display: flex;
  align-items: center;
  padding: 14px 12px;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 32px;
}

.title {
  font-size: 16px;
  line-height: 150%;
  font-weight: 700;
  margin-right: 10px;
}

.filterAmountRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--color-bg-secondary);
  padding: 12px 0;
  row-gap: 12px;
  overflow-y: auto;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #C7D4DB;
}

.bottomButtons {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  padding: 8px 12px;
}
