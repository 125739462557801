.container {
  margin-top: 24px;
  display: block;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 8px;
}

.empty {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-secondary);
}

.itemsList {
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
}
