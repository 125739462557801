.container {
  display: flex;
  align-items: center;
  color: #0078D2;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

.ico {
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  margin-left: 4px;
}
