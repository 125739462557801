.container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;

  margin-bottom: 8px;
}

.title {
  width: 80px;
  font-size: 14px;
  font-weight: 700;
}

.titleDate {
  font-size: 14px;
  font-weight: 700;
}
