.container {
  display: flex;
  flex-direction: column;
  min-width: 1366px;
}

.pageWrapper {
  min-height: calc(100vh - 130px);
}

.loader {
  display: flex;
  flex-direction: column;
  margin-top: 40vh;
}

.loaderTitle {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  color: var(--color-typo-link);
}
