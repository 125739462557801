.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.imgObjectDeleted {
  margin-top: 50px;
  margin-bottom: 24px;
  width: 420px;
  height: 280px;
}

.title {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
}

.subTitle {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-typo-secondary);
}
