.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 34px 20px 0 20px;
  min-height: 188px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fakeContainer {
  display: flex;
}

.fakeText {
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-secondary);
}

.title {
  height: 22px;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 4px;
}

.row {
  display: flex;
  margin-top: 16px;
  column-gap: 32px;
  margin-bottom: 24px;
}

.description {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-secondary);
}
