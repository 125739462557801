.container {
  display: flex;
  flex-direction: column;
}

.choiceGroup_table {
  width: 100%;
  margin: 16px 0px;
}

.choiceGroup_sidebar {
  width: 50%;
  margin-bottom: 16px;
}

.row {
  display: flex;
  width: 100%;
}

.resetBtnWrapper {
  margin: 16px 0px;
  display: flex;
  height: 20px;
  align-items: center;
}

.resetBtn {
  cursor: pointer;
}
