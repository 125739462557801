.container {
  display: flex;
  min-height: 28px;
  flex-shrink: 0;
}

.childrenContainer {
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
}


.flexEnd {
  justify-content: flex-end;
}
