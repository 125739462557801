.container {
  display: flex;
  align-items: center;
  width: 444px;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  white-space: pre-line;
  text-align: left;
}
