.container {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 16px;
  align-content: flex-start;
}

.nothingFound {
  font-size: 14px;
  line-height: 140%;
  color: var(--color-typo-secondary);
}
