.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 20px 6px 20px;
}

.controlRow {
  display: flex;
  margin-bottom: 12px;
}

.rightColumn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.vertDelimiter {
  display: flex;
  margin: 8px 12px;
  width: 1px;
  background-color: var(--color-bg-border);
}

.filterBarContainer {
  min-height: 28px;
}
