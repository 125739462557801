.container {
  display: flex;
}

.dropDownIco {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 2px 8px 0 4px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.dropDownIco:hover {
  background: linear-gradient(0deg, rgba(0, 32, 51, 0.05), rgba(0, 32, 51, 0.05)),
  linear-gradient(0deg, rgba(0, 32, 51, 0.05), rgba(0, 32, 51, 0.05));
}

