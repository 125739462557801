.container {
  display: flex;
  min-width: 400px;
  max-width: 550px;
  flex-direction: column;
  align-items: center;
  padding: 48px 32px;
  z-index: 999;
}

.title {
  color: var(--color-control-bg-primary);
  font-weight: 600;
}

.errorMessage {
  margin-top: 24px;
  text-align: center;
  font-weight: 500;
  white-space: pre-line;
}

.btn {
  display: flex;
  margin-top: 48px;
}
