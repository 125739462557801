.container {
  display: flex;
  width: 100%;
  height: calc(100vh - 289px);
  background-color: var(--color-bg-secondary);
}

.title {
  display: flex;
  width: 100%;
  margin-top: 24px;
  font-size: 14px;
  justify-content: center;
  color: var(--color-typo-secondary);
}
