.container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  height: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 169px);
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: var(--color-bg-default);
  padding: 24px 24px 40px 24px;
  margin-bottom: 60px;
  row-gap: 24px;
}

.bottomButtons {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  padding: 8px 12px;
  background-color: var(--color-bg-default);
}

.section {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 16px;
  line-height: 24px;
}

.sectionTitle {
  color: var(--color-typo-secondary);
}

.sectionValue {
  color: var(--color-typo-primary);
}
