.container {
  display: flex;
  overflow: hidden;
}

.tableContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: calc(100vh - 268px);
  padding: 8px 20px;
  overflow: hidden;
}

.tableWrapper {
  border-radius: 4px;
  border: 1px solid var(--color-control-bg-border-default);;
  background-color: var(--color-bg-default);
  width: calc(100vw - 40px);
  overflow-y: auto;
}

.tableWrapperOpenHierarchy {
  width: calc(100vw - 480px)!important;
}

.nothingToShow {
  width: calc(100vw - 40px);
  text-align: center;
  margin-top: 32px;
}

.nothingToShowTitle {
  color: var(--color-typo-secondary);
  white-space: pre-line;
}

.button {
  color: var(--color-typo-link);
  cursor: pointer;
}
