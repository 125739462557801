.container {
  display: flex;
  flex-direction: column;
  padding: 0 16px 8px 8px;
}

.row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 10px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: var(--color-typo-secondary);
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: var(--color-bg-border);
}

.selectedItemsList {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.firstItem {
  padding: 4px 0;
}
