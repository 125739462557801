.container {
  display: flex;
}

.ico {
  width: 16px;
  margin-right: 8px;
}

.text {
  font-size: 14px;
  color: var(--color-typo-secondary);
}

