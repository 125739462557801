.container {
  display: flex;
  width: 100%;
  height: calc(100% - 32px);
}

.leftColumn {
  display: flex;
}

.rightColumn {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: var(--color-bg-border);
}
