.container {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  height: 100%;
  margin-top: 12px;
  font-size: 14px;
  justify-content: center;
  color: var(--color-typo-secondary);
}
