.container {
  display: flex;
  width: 391px;
  height: 180px;
  padding: 40px 32px;
  flex-direction: column;
  justify-content: space-between;;
  border-radius: 8px;
  border: 1px solid rgba(0, 65, 102, 0.20);
  background-color: var(--color-bg-default);
}

.topRow {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: var(--color-typo-secondary);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.amountBlock {
  display: flex;
  flex-direction: column;
}

.amountItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.amount {
  color: var(--color-typo-brand, #0071B2);
  font-size: 28px;
  font-weight: 400;
  line-height: 100%;
}

.amountDescription {
  font-size: 12px;
  color: var(--color-typo-brand, #0071B2);
  text-align: right;
}
