.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: center;
  white-space: pre-line;
}

.humanImage {
  width: 300px;
}
