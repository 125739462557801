.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
}

.ico {
  margin-right: 4px;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-secondary);
}
