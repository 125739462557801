.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 66px;
  min-height: calc(100vh - 640px);
}

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 16px;
}

.detailedlist {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 20px;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.leftPart {
  display: flex;
  align-items: center;
}

.titleSwitch {
  font-size: 16px;
  line-height: 19px;
  margin-right: 8px;
}
