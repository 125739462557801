.container {
  width: 100%;
  border-radius: 4px;
  background-color: var(--color-bg-default);
  padding: 20px 24px;
}

.shortContainer {
  width: 100%;
  border-radius: 0;
  background-color: var(--color-bg-default);
  padding: 20px 24px;
}

.tittleContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  max-width: 562px;
  overflow-x: auto;
}

.content {
  min-height: 25px;
}

.delimiter {
  margin-top: 20px;
  width: calc(100%);
  height: 1px;
  background-color: var(--color-control-bg-border-default);
}

.childrenContainer {
  padding: 24px 0 40px 0;
}

.shortChildrenContainer {
  padding: 24px 0 16px 0;
}

.container + .container {
  margin-top: 32px;
}

.shortContainer + .shortContainer {
  margin-top: 12px;
}

.ico {
  width: 16px;
  height: 16px;
  margin-top: 4px;
  margin-right: 4px;
}
