.container {
  width: 1206px;
  height: 480px;
  margin-top: 40px;
  margin-bottom: 60px;
  padding: 72px 48px;
  gap: 104px;
  border-radius: 16px;
  background-image: url("../../../assets/mainPage/heroBanner.png");
}

.title {
  margin-bottom: 16px;
  color: #FAFAFA;
  font-size: 48px;
  font-weight: 600;
  line-height: 120%;
  white-space: pre-line;
}

.subtitle {
  margin-bottom: 104px;
  color: #FAFAFA;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
}
