.container {
  display: flex;
  flex-shrink: 0;
  padding-left: 8px;
}

.title {
  font-size: 16px;
  line-height: 150%;
}
