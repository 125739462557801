.container {
  display: flex;
}

.radioButtonGroup {
  display: flex;
  flex-direction: column;
}

.groupTitle {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--color-typo-secondary);
}

.buttonsRow {
  margin-bottom: 8px;
  display: flex;
  column-gap: 20px;
}

.groupDescription {
  padding: 0 8px;
  font-size: 12px;
  color: var(--color-typo-ghost);
  white-space: pre-line;
}
