.container {
  display: flex;
}

.radioButtonGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.groupTitle {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--color-typo-secondary);
}

.buttonsRow {
  margin-bottom: 8px;
  display: flex;
  column-gap: 20px;
}

.ico {
  width: 16px;
  height: 16px;
  margin-top: 4px;
  margin-right: 4px;
}

.toolTip {
  margin-left: -16px;
}

.limitsText {
  font-size: 16px;
}
