.container {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  align-items: center;
}

.ico {
  margin-right: 4px;
}

.icoArrow {
  margin: 2px 4px 0 4px;
}

.linkToSystem {
  color: var(--color-typo-link);
  cursor: pointer;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edgeLevel {
  color: var(--color-typo-secondary);
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
