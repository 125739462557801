.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

.growContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.filterBarContainer {
  min-height: 28px;
}

