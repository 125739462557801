.container {
  display: flex;
  padding: 0 12px;
  flex-direction: column;
  row-gap: 8px;
}

.filterRow {
  display: flex;
}

