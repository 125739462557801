.container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  background-color: var(--color-bg-default);
  padding: 24px 24px 40px 24px;
  row-gap: 24px;
  max-width: 632px;
}

.section {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 16px;
  line-height: 24px;
}

.sectionTitle {
  color: var(--color-typo-secondary);
}

.sectionValue {
  color: var(--color-typo-primary);
}

.row {
  display: flex;
}

.tooltip {
  margin-top: 2px !important;
}

.informer {
  margin-left: 4px !important;
  cursor: pointer !important;
}

.ico {
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  margin-left: 4px;
}
