.wrapper {
  width: 100%;
  margin-top: 12px;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: var(--color-bg-default);
}
