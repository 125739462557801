.input {
  width: 100%;
}

.divider {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.error {
  margin-top: 1px;
  color: #ff4d4f;
}