.container {
  display: flex;
  width: 100%;
  height: 240px;
  padding: 40px 32px;
  border-radius: 8px;
  border: 1px solid rgba(0, 65, 102, 0.20);
  background-color: var(--color-bg-default);
}

.leftPart {
  display: flex;
  flex-direction: column;
  width: 420px;
  margin-right: 40px;
  flex-shrink: 0;
}

.verticalDelimiter {
  display: flex;
  width: 1px;
  height: 100%;
  background-color: var(--color-bg-border);
}

.rightPart {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 40px;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.assetsAmountColumn {
  display: flex;
  flex-direction: column;
  min-width: 95px;
  flex-shrink: 0;
  margin-left: 40px;
  align-items: flex-end;
}

.topRow {

}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 16px;
}

.description {
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--color-typo-secondary);
}

.buttonOpen {
  width: 100px;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
}

.amountBlock {
  display: flex;
  flex-direction: column;
}

.amountItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.amount {
  color: var(--color-typo-brand, #0071B2);
  font-size: 28px;
  font-weight: 400;
  line-height: 100%;
}

.amountDescription {
  font-size: 12px;
  color: var(--color-typo-brand, #0071B2);
  text-align: right;
}
