.container {
  position: absolute;
  display: flex;
  width: 100%;
  top: 45px;
  padding: 16px 24px;
  background-color: var(--color-bg-default);
  border-radius: 4px;
  overflow-y: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 32, 51, 0.16), 0 2px 2px 0 rgba(0, 32, 51, 0.02);
  max-height: 268px;
  z-index: 2;
}

.container::-webkit-scrollbar-track {
  background-color: var(--color-bg-default);
  border-radius: 4px;
}

.nothingFindTitle {
  color: var(--color-typo-secondary, rgba(0, 32, 51, 0.60));
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.searchHintsList {
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 6px;
}

.searchHint {
  display: flex;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  cursor: default;
  color: rgba(0, 32, 51, 0.3);
}

.searchHint:hover {
  background-color: rgba(0, 66, 105, 0.05);
}

