.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
}

.contentCollapse {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 24px;
}

.groupContainer {
  display: flex;
  flex-direction: column;
}

.groupTitle {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--color-typo-secondary);
}

.groupRow {
  margin-bottom: 8px;
  display: flex;
  column-gap: 20px;
}
