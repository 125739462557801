.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.paginationContainer {
  display: flex;
  width: 100%;
  padding: 8px 24px;
  background-color: var(--color-bg-default);
}
