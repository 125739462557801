.container {
  display: flex;
  width: 100%;
  margin-left: 4px;
  justify-content: space-between;
  height: 24px;
}

.dropDown {
  display: flex;
}
