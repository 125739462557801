.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 80px;

  height: calc(100vh - 141px);
  overflow-y: auto;
}

.generalSection {
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 24px;
  padding: 24px 24px 40px 24px;
  background-color: var(--color-bg-default);
}

.sectionItem {
  display: flex;
  flex-direction: column;
}

.sectionItemTitle {
  font-size: 16px;
  color: var(--color-typo-secondary);
  margin-bottom: 8px;
}

.sectionItemValue {
  font-size: 16px;
  white-space: pre-line;
}

.row {
  display: flex;
}

.ico {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.usersGroup {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.userIco {
  height: 24px;
  object-fit: contain;
  margin-right: 16px;
}
