.container {
  display: flex;
  height: calc(100vh - 92px);
  overflow: hidden;
}

.content {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.topRow {
  display: flex;
  align-items: center;
}

.title {
  width: 100%;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #C7D4DB;
}

.bottomButtons {
  display: flex;
  width: 440px;
  flex-shrink: 0;
  justify-content: space-between;
  column-gap: 10px;
  padding: 8px 12px;
}

.children {
  display: flex;
  flex-direction: column;
  width: 440px;
  height: 100%;
  flex-shrink: 0;
  white-space: nowrap;
  border-right: 1px solid var(--color-bg-border);
}

.enter {
  transform: scaleX(0);
  width: 0;
}

.enterActive {
  width: 440px;
  transition:width 0.3s ease-in-out;
}

.exit {
  width: 440px;
}

.exitActive {
  width: 0;
  transition:width 0.3s ease-in-out;
}

.exitDone {
  width: 0;
}

.clearAllBtn {
  display: flex;
  height: 60px;
  justify-content: flex-end;
  padding-right: 12px;
  align-items: center;
  flex-shrink: 0;
}

.clearAllBtn:active {
  transform: scale(0.95)
}

.clearBtnText {
  color: var(--color-typo-link);
}

.hierarchyWrapper {
  padding-top: 16px;
  margin-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  height: calc(100vh - 202px);
}

.hierarchyWrapper::-webkit-scrollbar-track {
  background-color: var(--color-bg-default);
}

