.filterContainer {
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #00416633;
  border-radius: 4px;
  width: 400px;
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonsWrapper {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
