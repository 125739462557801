.container {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  min-width: 1366px;
  height: calc(100vh - 60px);
  overflow: hidden;
}






