.container {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.titleText {
  width: 400px;
  height: 18px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
  overflow: hidden;
}
