.container {
  display: flex;
  flex-direction: column;
  width: 640px;
  height: 100%;
}

.loaderContainer {
  margin-top: 32px;
  width: 640px;
}
