.container {
  display: flex;
  flex-direction: column;
}

.choiceGroup {
  width: 204px;
  margin-bottom: 8px;
}

.row {
  display: flex;
  width: 100%;
}
