.container {
  display: flex;
  width: 640px;
  height: 100%;
  flex-direction: column;
}

.stepBarContainer {
  display: flex;
  flex-direction: column;
  width: 640px;
  padding: 12px 24px 12px 24px;
  background-color: var(--color-bg-default);
}

.contentContainer {
  width: 100%;
  height: 100%;
}
