.container {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}

.content {
  padding-left: 28px;
}

.search {
  display: flex;
  flex-grow: 1;
}

.itemsContainer {
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  row-gap: 8px;
}

.loaderContainer {
  display: flex;
  height: 16px;
  align-items: center;
}
