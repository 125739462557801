.wrapper {
  display: flex;
  width: 100%;
  margin-top: -12px;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 274px);
  margin-top: 12px;
  background-color: var(--color-bg-secondary);
  overflow-y: auto;
}
