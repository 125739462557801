.container {
  display: flex;
  width: 100%;
  height: 94px;
}

.badgeContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
