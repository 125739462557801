.container {
  display: flex;
  flex-direction: column;
}

.textField {
  display: flex;
  width: 100%;
  flex-grow: 0;
  margin-bottom: 8px;
}
