.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 20px 6px 20px;
}

.controlRow {
  display: flex;
  min-height: 32px;
  margin-bottom: 12px;
  justify-content: flex-end;
}

.filterBarContainer {
  min-height: 28px;
}
