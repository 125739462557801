.container {
  height: 41px;
}

.constantHeight {
  height: 40px;
}


.wrapper {
  padding: 18px 20px 0 20px;
}

.delimiter {
  width: 100%;
  height: 1px;
  background-color: var(--color-control-bg-border-default);
}

.tab {
  display: flex;
  align-items: center;
  color: var(--color-typo-secondary);
  margin-bottom: 6px;
  font-size: 16px;
  opacity: 1;
  cursor: pointer;
}

.tabActive {
  color: var(--color-typo-primary);
  transition: opacity .2s;
}
