body {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

:root {
  --widthCard: 256px;
  --maxWidthCard: 256px;
  --lineWidth: 1px;
  --contentWidth: 1206px
}

.Modal {
  z-index: 10000;
}

