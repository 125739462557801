.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  min-width: 1366px;
  height: calc(100vh - 60px);
  overflow: hidden;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.snackBarContainer {
  position: absolute;
  bottom: 60px;
  right: 60px;
}
