.container {
    display: flex;
    flex-direction: column;
  }
  
  .resetBtnWrapper {
    font-size: 14px;
    font-weight: 400;
    margin: 16px 0px;
    display: flex;
    height: 20px;
    align-items: center;
  }

  .resetBtn {
    cursor: pointer;
  }