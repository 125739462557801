.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pagination {
  padding: 8px 24px;
  background-color: var(--color-bg-default);
}

.title {
  display: flex;
  height: 100%;
  margin-top: 24px;
  font-size: 14px;
  justify-content: center;
  color: var(--color-typo-secondary);
}

.controlRow {
  padding: 24px 20px 13px 20px !important;
}

.rowTotalObjectsInfo {
  display: flex;
  justify-content: flex-end;
  background-color: var(--color-bg-default);
  padding-right: 20px;
}
