.container {
  display: flex;
  padding: 9px 12px;
  flex-direction: column;
  row-gap: 8px;
}

.scroll {
  max-height: 280px;
  border-radius: 4px;
  border: 1px solid var(--color-control-bg-border-default);
  overflow-y: auto;
}
