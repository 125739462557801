.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-bg-default);
  padding: 0 15px;
  overflow-y: hidden;
  flex-shrink: 0;
}

.label {
  height: 59px;
  display: flex;
  align-items: center;
  padding: 0 9px;
}

.title {
  max-width: 460px;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  margin-right: 12px;
}

.resetBtn {
  color: var(--color-typo-link);
}

.delimiter {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #C7D4DB;
}

.collapsePart {
  display: flex;
  flex-direction: column;
}

.collapseContent {
  padding: 12px 9px 24px 9px
}
