.container {
  display: flex;
  width: 100%;
  padding: 24px 20px 20px 20px;
  overflow: hidden;
}

.containerPreviewTab {
  padding: 12px 20px 20px 20px;
}

.informer {
  margin-bottom: 12px;
}

.tableContainer {
  display: flex;
  width: 100%;
  height: calc(100vh - 414px);
  flex-direction: column;
  overflow: hidden;
}

.tableContainerPreviewTab {
  height: calc(100vh - 402px);
}

.tableWrapper {
  border-radius: 4px;
  border: 1px solid var(--color-control-bg-border-default);
  background-color: var(--color-bg-default);
  overflow: auto;
}

.nothingFound {
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 140%;
  color: var(--color-typo-secondary);
}

/*.informer {*/
/*  margin-top: -8px;*/
/*  z-index: 1;*/
/*}*/
