.container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: -18px;
}
.combineText {
  display: flex;
}

.sectionTitle {
  color: var(--color-typo-secondary);
}

.requiredSign {
  font-size: 16px;
  margin-left: 4px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-typo-alert);
}

.textFieldContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
}

.sectionAlert {
  height: 18px;
  margin-top: 4px;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-typo-alert);
}
