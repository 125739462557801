.container {
  display: flex;
  padding: 0 8px;
  border-radius: 4px;
  column-gap: 4px;
}

.container:hover {
  background-color: var(--color-control-bg-clear-hover);
}

.textButton {
  display: flex;
  align-items: center;
  color: var(--color-typo-link);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
  margin: 6px 0;
  padding: 0 4px;
  border-radius: 4px;
  transition: background-color 0.35s ease;
}

.textButton + .textButton {
  margin-left: 20px;
}

.textButtonDisable {
  color: var(--color-typo-ghost);
}
