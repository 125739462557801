.container {
  width: 391px;
  padding: 24px 32px;
  border-radius: 4px;
  background: var(--color-bg-default);
}

.linkToSystem {
  color: var(--color-typo-link);
  cursor: pointer;
}

.sourceContainer {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.sourceIco {
  height: 20px;
  object-fit: contain;
}

.source {
  color: var(--color-typo-secondary);
}

.subTitle {
  font-size: 14px;
}

.subValue {
  margin-top: 4px;
  font-size: 14px;
  color: var(--color-typo-secondary);
  margin-bottom: 8px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;

}
