.wrapper {
  margin-bottom: 6px;
}
.container {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.buttonsGroup {
  display: flex;
  column-gap: 24px;
}

.badgeSection {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.alertRow {
  height: 18px;
  margin-top: 8px;
}

.alert {
  font-size: 12px;
  color: var(--color-typo-alert);
}
