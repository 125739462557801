.container {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 12px;
  padding: 0 20px;
}

.rightColumn {
  display: flex;
  column-gap: 24px;
}
