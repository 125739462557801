.container {
  display: flex;
  align-items: center;
}

.title {
  font-size: 14px;
  color: var(--color-control-bg-primary);
  margin-right: 8px;
}
