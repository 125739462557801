.container {
  display: flex;
  flex-direction: column;
  width: 838px;
  padding: 32px 20px 20px 20px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
}

.delimiter {
  display: flex;
  margin: 24px 0;
  height: 1px;
  background-color: #B8CAD5;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.btnRow {
  display: flex;
  justify-content: space-between;
}

.noItems {
  margin-left: 16px;
  font-size: 16px;
  line-height: 120%;
}
