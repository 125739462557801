.container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 96px;
  background-color: var(--color-bg-default);
  padding: 24px 24px 40px 24px;
  max-width: 632px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 20px;
}

.sectionsGroup {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  row-gap: 24px;
}

.section {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 16px;
  line-height: 24px;
}

.sectionTitle {
  color: var(--color-typo-secondary);
}

.sectionValue {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
  color: var(--color-typo-primary);
}
