.container {
  display: flex;
  width: 84px;
  align-items: center;
}

.title {
  margin-left: 8px;
  font-size: 14px;
}

.containerScanning {
  display: flex;
  flex-direction: column;
  width: 84px;
}

.titleScanning {
  font-size: 12px;
  color: var(--color-typo-secondary);
}
