.container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  background-color: var(--color-bg-default);
  padding: 24px 24px 40px 24px;
  row-gap: 24px;
}

.section {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 16px;
  line-height: 24px;
}

.sectionTitle {
  color: var(--color-typo-secondary);
}

.sectionValue {
  color: var(--color-typo-primary);
}
