.container {
  display: flex;
  width: 100%;
  column-gap: 40px;
  padding: 24px 20px 48px 20px;
  overflow-y: auto;
}

.leftColumn {
  width: 775px;
}

.description {
  margin-top: 24px;
}
