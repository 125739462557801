.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
}

.container {
  display: flex;
  align-items: center;
  column-gap: 24px;
  margin-bottom: 24px;
}

.buttonsGroup {
  display: flex;
  column-gap: 24px;
}

.badgeSection {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.informer {
  margin-top: -18px;
  margin-bottom: 12px;
  font-size: 12px;
  color: var(--color-typo-ghost);
}
