.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 252px);
  margin-top: 12px;
  overflow-y: auto;
}
