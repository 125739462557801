.Collapse-Body {
  transition: max-height .1s;
}

.Collapse-Label {
  padding: 1px;
}

.Collapse-Content {
  padding: 1px;
}
