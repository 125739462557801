.container {
  display: flex;
  flex-direction: column;
}

.delimiterButton {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #C7D4DB;
}

.bottomButtons {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  padding: 8px 12px;
}
