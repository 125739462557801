.container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 16px;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: var(--color-typo-secondary);
}

.sourceIco {
  height:28px;
  padding: 4px 12px 4px 12px;
  border-radius: 255px;
  border: 1px solid var(--color-bg-secondary);
}


.source {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-typo-secondary);
}
