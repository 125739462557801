.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 20px;
  max-height: calc(100vh - 314px);
}

.buttonsRow {
  display: flex;
  column-gap: 40px;
}

.nothingFound {
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 140%;
  color: var(--color-typo-secondary);
}
