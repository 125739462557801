.container {
  display: flex;
  flex-direction: column;
  padding: 0 28px;
  flex-grow: 1;
  align-items: center;
  background-color: var(--color-bg-secondary);
  overflow-y: auto;
}

.containerTable {
  align-items: flex-start;
  padding: 0;
  overflow-y: hidden;
}

.nothingFound {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  color: var(--color-typo-secondary);
}
