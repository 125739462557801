.container {
  display: flex;
  width: 100%;
  max-width: 640px;
  padding: 12px 18px;
  justify-content: space-between;
  background-color: var(--color-bg-default);
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
