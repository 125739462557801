.container {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 8px;
}

.title {
  font-size: 14px;
  line-height: 100%;
  color: var(--color-typo-secondary);
  white-space: pre-line;
  word-wrap: break-word;
}

.groupTitle {
  color: var(--color-typo-primary);
}
