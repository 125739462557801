
.titleRow {
  display: flex;
  margin-bottom: 8px;
}

.title {
  margin-right: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
}

.ico {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  margin-right: 4px;
}

.buttonEdit {
  margin-top: -3px;
  display: flex;
  width: 24px;
  align-items: center;
  justify-content: center;
}

.buttonsRow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 2px;
  min-height: 32px;
}

.value {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  white-space: pre-line;
  color: var(--color-typo-secondary);
}

.placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-ghost);
}

.loaderContainer {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.loaderText {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-link);
  margin-left: 20px;
  margin-right: 14px;
}

.loader {
  width: 30px;
}
