.container {
	display: flex;
	flex-direction: column;
}

.scroll {
	max-height: 280px;
	border-radius: 4px;
	border: 1px solid var(--color-control-bg-border-default);
	overflow-y: auto;
}

.selectAll {
	position: sticky;
	top: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: white;
	padding: 16px 12px 8px 12px;
	opacity: 1;
	z-index: 1;
}

.item {
	display: flex;
	padding: 8px 12px;
}

.loaderWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 40px;
}

.loaderSelectAll {
  margin-top: 40px;
}
