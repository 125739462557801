.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.sectionItemTitle {
  font-size: 16px;
  color: var(--color-typo-secondary);
  margin-bottom: 8px;
}

.sectionItemValue {
  font-size: 16px;
  white-space: pre-line;
}
