.container {
  width: 528px;
  padding: 32px 20px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
  margin-bottom: 12px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 24px;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.delimiter {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background-color: var(--color-bg-border);
}

