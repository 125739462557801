.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 13px;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-default);
  padding-top: 24px;
  padding-bottom: 40px;
}

.infoSystemSelectorContainer {
  display: flex;
  padding: 0 24px 0 24px;
}

.bottomButtons {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  padding: 8px 12px;
  background-color: var(--color-bg-default);
}

.section {
  display: flex;
  padding: 0 24px;
  flex-direction: column;
  row-gap: 8px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}

.shortBottom {
  margin-bottom: 8px;
}

.combineText {
  display: flex;
}

.sectionTitle {
  color: var(--color-typo-secondary);
}

.requiredSign {
  font-size: 16px;
  margin-left: 4px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-typo-alert);
}

.textFieldContainer {
  display: flex;
  flex-direction: column;
}

.sectionAlert {
  height: 18px;
  margin-top: 4px;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-typo-alert);
}
