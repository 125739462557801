.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 240px);
  overflow-y: auto;
}
