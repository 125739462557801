.container {
  display: block;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 8px;
}

.row {
  display: flex;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-typo-secondary);
}

.container + .container {
  margin-top: 24px;
}


.ico {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-left: 4px;
}


.tooltip {
  margin-top: -4px !important;
}

.informer {
  cursor: pointer !important;
}
